export const DASHBOARD = 'Dashboard';
export const ACCOUNT = 'Account';
export const ADMINPAGE = 'Admin Page';
export const GROUPPAGE = 'Group Administration';
export const SMARTTEMPLATES = 'Smart Workflows';
export const PROJECT_OVERVIEW = 'Project Overview';
export const CREATE_NEW_PROJECT = 'Create a New Project';
export const CREATE_NEW_TEMPLATE = 'Create a New Smart Workflow';
export const SETUP_FAVOURITE_TEMPLATES = 'Select Your Favorite Workflows';
export const DAILY_UPDATE = 'Close the Project';
export const LIBRARY_BOARD = 'Library Board';
export const BOARD = 'Project Breakdown';
export const PROJECT_DASHBOARD = 'Project Dashboard';
export const BOARD_DETAIL = 'Board Detail';
export const SPRINTVIEW = 'Timeline';
// export const SPRINTVIEW = 'Schedules';
// export const SPRINTVIEW = 'Work Schedule';
// export const TEMPLATE_ACCESS = 'Workflow Access Control';
export const PROJECT_DOCS = 'Plan';
export const SCHEDULE_REPORT = 'Schedule';
export const ANALYTICS = 'Analytics';
export const ATTACHMENTS = 'Attachments';
export const GUIDANCE = '??';
export const HELP = 'Help';

export const TYPE_PROJECT = 'Project';
export const TYPE_WORKFLOW = 'Workflow';
export const LIBRARY_TASK_COL_UUID = 1;
export const LIBRARY_WP_COL_UUID = 2;
